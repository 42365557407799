<template>
    <div class="content-wrapper">
        <validation-observer
            v-slot="{ handleSubmit }" ref="VForm"
        >
            <b-form @submit.prevent="handleSubmit(doSubmitCRUD('VForm', $refs))">
                <div class="content pt-0">
                    <div class="card mb-1">
                    </div>
                    <template>
                        <div class="card mb-1">
                            <div class="card-body bg_card_blue">
                                <div class="row">
                                <div class="col-md-9">
                                    <table class="table table-borderless table-sm text-uppercase">
                                    <tbody>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Rekam Medis</h4>
                                            <p>{{rowPasien.ap_code||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Pasien</h4>
                                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tempat, Tanggal Lahir </h4>
                                            <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Usia</h4>
                                            <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Jenis Kelamin</h4>
                                            <p>{{rowPasien.cg_label||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card mb-1">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Rujukan Dokter</h6>
                            </div>
                            <div class="card-body mt-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table table-bordered">
                                            <template>
                                            <thead>
                                                <tr class="table-secondary">
                                                <th colspan="4">
                                                {{getConfigDynamic(Config.mr.typeRujukan,dataRM.artlr_type)}}</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataRM.artlr_type == 'RI'">
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Dirujuk Ke</h4>
                                                    <v-select placeholder="Dirujuk Ke" v-model="dataRM.artlr_poli"
                                                    :options="mPoliInternal" label="text" :clearable="true"
                                                    :reduce="v=>v.value"></v-select>
                                                    <VValidate 
                                                        name="Dirujuk Ke" 
                                                        v-model="dataRM.artlr_poli" 
                                                        :rules="{required : 1}"
                                                    />
                                                    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Dokter Rujukan</h4>
                                                    <v-select placeholder="Dokter Rujukan" v-model="dataRM.artlr_dokter"
                                                    :options="mDokterIntenal" label="text" :clearable="true"
                                                    :reduce="v=>v.value"></v-select>
                                                    <VValidate 
                                                        name="Dokter Rujukan" 
                                                        v-model="dataRM.artlr_dokter" 
                                                        :rules="{required : 1}"
                                                    />
                                                    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Catatan</h4>
                                                    <p>{{dataRM.artlr_notes||"-"}}</p>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataRM.artlr_type == 'RK' || dataRM.artlr_type == 'RP'">
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Alasan Merujuk</h4>
                                                    <p>{{dataRM.artlr_alasan_rujuk||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>{{dataRM.artlr_alasan_rujuk||"-"}}</h4>
                                                    <p>{{dataRM.artlr_alasan_rujuk_text||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Faskes</h4>
                                                    <p>{{dataRM.mrfj_name||"-"}}</p>
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Dokter</h4>
                                                    <p>{{dataRM.artlr_nama_dokter_rujuk||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Peralatan Media yang Terpasang</h4>
                                                    <p v-if="dataRM.artlr_is_media == 'Y'">
                                                    {{dataRM.artlr_media||"-"}}</p>
                                                    <p v-else> - </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Perawatan Pasien yang dibutuhkan</h4>
                                                    <p>{{dataRM.artlr_perawatan_pasien||"-"}}</p>
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Persetujuan Biaya RS Rujukan</h4>
                                                    <p>{{dataRM.artlr_is_biaya == "Y"?"Ya":"Tidak"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Persetujuan Risiko Perjalanan</h4>
                                                    <p>{{dataRM.artlr_is_risiko == "Y"?"Ya":"Tidak"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Kejadian klinis selama dilakukan transfer</h4>
                                                    <p v-if="dataRM.artlr_is_kejadian_klinis == 'Y'">{{dataRM.artlr_kejadian_klinis||"-"}}</p>
                                                    <p v-else> Tidak </p>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataRM.artlr_type == 'RB' || dataRM.artlr_type == 'RBN'">
                                                <tr>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4>No Rujukan</h4>
                                                        <p> {{row.arres_no_rujukan||"-"}} </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4>No SRB</h4>
                                                        <p> {{row.arr_no_rujuk_balik||"-"}} </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4>Catatan</h4>
                                                        <p>{{dataRM.artlr_notes||"-"}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="dataRM.artlr_type == 'RB'">
                                                    <td colspan="2">
                                                        <div class="result_tab">
                                                        <h4>Diagnosa Rujuk Balik <small class="txt_mandatory">*</small></h4>
                                                        <v-select placeholder="Diagnosa Rujuk Balik" v-model="row.arr_diagnosa_rujuk_balik"
                                                        :options="Config.mr.diagnosaRujukBalik" label="text" :clearable="true"
                                                        :reduce="v=>v.value"></v-select>
                                                        <VValidate 
                                                            name="Diagnosa Rujuk Balik" 
                                                            v-model="row.arr_diagnosa_rujuk_balik" 
                                                            :rules="{required : 1}"
                                                        />
                                                        </div>
                                                    </td>
                                                </tr>

                                                
                                            </tbody>
                                            </template>
                                        </table>
                                    </div>
                                    <div class="col-md-4" v-if="dataRM.artlr_type == 'RI' && 
                                    row.arr_status == 'D'">
                                        <div class="wrap_line mt-2">
                                        <h3 class="pb-1">Rujukan Internal</h3>
                                        <a href="javascript:;" @click="downloadReport('suratRujukanInternal','Surat Rujukan Internal')" class="btn btn-success btn-labeled btn-labeled-left">
                                            <b><i class="icon-download"></i></b>
                                            <span>Unduh</span>
                                        </a>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="dataRM.artlr_type == 'RK' && 
                                    row.arr_status == 'D'">
                                        <div class="wrap_line mt-2">
                                        <h3 class="pb-1">Rujukan Kasus</h3>
                                        <a href="javascript:;" @click="downloadReport('suratRujukanExternal','Surat Rujukan External')" class="btn btn-success btn-labeled btn-labeled-left">
                                            <b><i class="icon-download"></i></b>
                                            <span>Unduh</span>
                                        </a>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4" v-if="dataRM.artlr_type == 'RP' 
                                    && row.arr_status == 'D'">
                                        <div class="wrap_line mt-2">
                                        <h3 class="pb-1">Rujukan Parsial</h3>
                                        <a href="javascript:;" @click="downloadReport('suratRujukanExternal','Surat Rujukan External')" class="btn btn-success btn-labeled btn-labeled-left">
                                            <b><i class="icon-download"></i></b>
                                            <span>Unduh</span>
                                        </a>
                                        </div>
                                    </div>

                                     <div class="col-md-4" v-if="(dataRM.artlr_type == 'RK'||dataRM.artlr_type == 'RP') && row.arr_is_created_eksternal == 'Y'">
                                        <div class="wrap_line mt-2">
                                        <h3 class="pb-1">Rujukan BPJS</h3>
                                        <a href="javascript:;" @click="downloadReport('downloadRujukanBPJS','Surat Rujukan BPJS')" class="btn btn-success btn-labeled btn-labeled-left">
                                            <b><i class="icon-download"></i></b>
                                            <span>Unduh</span>
                                        </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        
                        <div class="card" v-if="user.levelId == uFarmasi">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Resep Dokter</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Jenis</th>
                                                <th>Nama</th>
                                                <th>Jumlah</th>
                                                <th>Frekuensi</th>
                                                <th>Signa 1</th>
                                                <th>Signa 2</th>
                                                <th>keterangan</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v,k) in resepDokter" :key="v.id">
                                                <td>{{k+1}}</td>
                                                <td>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</td>
                                                <td>{{v.ard_nama}}</td>
                                                <td>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</td>
                                                <td>
                                                    <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                                    <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                                                </td>    
                                                <td>
                                                    <b-form-input :disabled="row.arr_no_rujuk_balik" v-model="v.ard_signa1" :formatter="$parent.numberOnly" type="text" class="form-control" />
                                                </td>
                                                <td>
                                                    <b-form-input :disabled="row.arr_no_rujuk_balik" v-model="v.ard_signa2" :formatter="$parent.numberOnly" type="text" class="form-control" />
                                                </td>

                                                <td>{{v.ard_keterangan || "-"}}</td>
                                            </tr>
                                            <tr v-if="!(resepDokter||[]).length">
                                                <td colspan="99" class="text-center">Tidak ada Resep</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="card">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Buat Surat Rujukan</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>Tanggal Rujukan<small class="txt_mandatory">*</small></label>
                                    <div class="input-group">
                                        <div class="input-group mb-3">
                                        <datepicker :disabledDates="disabledDates"
                                            input-class="form-control transparent" placeholder="Tanggal Rujukan"
                                            class="my-datepicker" calendar-class="my-datepicker_calendar"
                                            v-model="row.arr_tanggal_rujukan">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="Tanggal Rujukan" 
                                        v-model="row.arr_tanggal_rujukan" 
                                        :rules="toValidate(mrValidation.arr_tanggal_rujukan)"
                                    />
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label>Rujukan Berlaku Hingga<small class="txt_mandatory">*</small></label>
                                    <div class="input-group">
                                        <div class="input-group mb-3">
                                        <datepicker :disabledDates="disabledDates"
                                            input-class="form-control transparent" placeholder="Rujukan Berlaku Hingga"
                                            class="my-datepicker" calendar-class="my-datepicker_calendar"
                                            v-model="row.arr_rujukan_exp_date">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <VValidate 
                                        name="Rujukan Berlaku Hingga" 
                                        v-model="row.arr_rujukan_exp_date" 
                                        :rules="toValidate(mrValidation.arr_rujukan_exp_date)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Pelayanan<small class="txt_mandatory">*</small></label>
                                        <v-select disabled placeholder="Pilih Pelayanan" v-model="row.arr_pelayanan"
                                        :options="Config.mr.mPelayanan" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>

                                        <VValidate 
                                            name="Pelayanan" 
                                            v-model="row.arr_pelayanan" 
                                            :rules="toValidate(mrValidation.arr_pelayanan)"
                                        />
                                    </div>
                                    
                                </div>
                                
                                <template v-if="(dataRM.artlr_type == 'RK' || dataRM.artlr_type == 'RP')">
                                
                                    <div class="col-md-12">
                                        <div class="form-group">
                                        <label>Tipe Rujukan<small class="txt_mandatory">*</small></label>
                                        <b-form-radio-group disabled
                                            :options="Config.mr.mRujukan"
                                            v-model="row.arr_type_rujukan"
                                        />
                                        <VValidate 
                                            name="Tipe Rujukan" 
                                            v-model="row.arr_type_rujukan" 
                                            :rules="toValidate(mrValidation.arr_type_rujukan)"
                                        />
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Faskes/RS Tujuan Rujukan<small class="txt_mandatory">*</small></label>

                                        <v-select placeholder="Pilih Faskes/RS Tujuan Rujukan" v-model="row.arr_faskes" @input="selectPoli($event)"
                                        :options="mFakesRujukan" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                        <VValidate 
                                            name="Faskes/RS Tujuan Rujukan" 
                                            v-model="row.arr_faskes" 
                                            :rules="toValidate(mrValidation.arr_faskes)"
                                        />

                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Spesialis/Poli Tujuan<small class="txt_mandatory">*</small></label>
                                        <v-select placeholder="Pilih Spesialis/Poli Tujuan" v-model="row.arr_poli" disabled
                                        :options="mPoli" @input="selectDokter($event)" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                        <VValidate 
                                            name="Spesialis/Poli Tujuan" 
                                            v-model="row.arr_poli" 
                                            :rules="toValidate(mrValidation.arr_poli)"
                                        />

                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                        <label>Nama Dokter</label>
                                        <b-form-input v-model="row.arr_nama_dokter_rujukan" type="text" class="form-control" />
                                        </div>
                                    </div>
                                </template>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label> Diagnosa<small class="txt_mandatory">*</small></label>
                                        <b-textarea v-model="row.arr_diagnosa_text" disabled rows="3" cols="3"     class="form-control" placeholder="" spellcheck="false">
                                        </b-textarea>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                
                                    <div class="form-group">
                                    <label> Catatan<small class="txt_mandatory">*</small></label>
                                    <b-textarea v-model="row.arr_catatan" rows="3" cols="3"     class="form-control" placeholder="" spellcheck="false">
                                    </b-textarea>
                                    <VValidate 
                                        name="Catatan" 
                                        v-model="row.arr_catatan" 
                                        :rules="{required : 1, min : 5}"
                                    />

                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="text-right">
                                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                                
                                <!--
                                <button type="button" @click="addSEP()" v-if="row.arr_rujukan == 'K' && row.arres_jaminan == 3" class="btn btn-primary ml-2 mr-2">Buat SEP<i class="icon-add ml-2"></i></button>
                                -->

                                <button type="submit" v-if="row.arr_status !== 'D'" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- /dashboard content -->
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>


<script>
    import GlobalVue from '@/libs/Global.vue'
    import Gen from '@/libs/Gen.js'
    const moment = require('moment')
    import Datepicker from 'vuejs-datepicker'
    import _ from 'lodash'
    import $ from 'jquery'
    
    export default {
        extends: GlobalVue,    
        components: {
            Datepicker
        }, 
        data(){
            return {
                inpNoRujuk: "",
                rowPasien:{},
                mDiagnosa:[],
                mFakesRujukan:[],
                mPoli:[],
                mDokter:[],
                mPoliInternal:[],
                mDokterInternal:[],
                dataRM: {},
                inputICD10Name: null,
                resepDokter: [],
                disabledDatesCaraBayar: null
            }
        },
        computed: {
            isAdd(){ return true},
            disabledDates() {
                return {
                    to: new Date(moment().format('YYYY-MM-DD'))
                }
            },
        },
        methods: {   
            downloadReport(link,name){
                let data = {
                    exptype: 'pdf', type: "export", pasienId: this.row.arr_ap_id, regId: this.row.arr_reg_id
                }

                let self = this

                $.ajax({
                        type: "POST",
                        url: process.env.VUE_APP_API_URL + `/report/${link}?token=IXs1029102asoaksoas102901290`,
                        data: data,
                        cache: false,
                        xhrFields:{
                            responseType: 'blob'
                        },
                        success: data => 
                        {
                            self.loadingOverlay = false
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(data)
                            link.download = `${name}-${moment().format("YYYY-MM-DD")}.pdf`
                            link.click()
                        },
                        fail: data => {
                            self.loadingOverlay = false
                            alert('Not downloaded')
                        }
                })
            }, 
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            ceilData(value) {
                return Math.ceil(value)
            },
            selectICD10(e) {
                let index = this.mDiagnosa.findIndex(x => x.value == e)
                if (index !== -1) {
                    this.inputICD10Name =  this.mDiagnosa[index]['text']
                }else{
                    this.inputICD10Name =  null
                }
            },
            filterICD10: _.debounce(function (e, k) {
                Gen.apiRest('/do/' + this.modulePage, {
                    data: {
                        type: 'select-icd-10',
                        search: e,
                        id: []
                    }
                }, 'POST').then(res => {
                    this.mDiagnosa = res.data.data
                })
            }, 10),
            changePageICD10(page, k, act, col) {
                if (act == 'min') {
                    if (page > 1) {
                        page -= 1
                        this[col][k]['currentPage'] -= 1
                    }
                    } else {
                    if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                        page += 1
                        this[col][k]['currentPage'] += 1
                    }
                }

                let data = {
                    type: 'select-paging-icd-10',
                    page: page,
                    search: this[col][k]['search']
                }
                Gen.apiRest(
                    "/do/" + this.modulePage, {
                        data: data
                    },
                    "POST"
                ).then(res => {
                    this.mDiagnosa = res.data.data
                })
            },
            toValidate(val){
                return {...val}
            },
            searchPasien(){
                let data = {
                    type : "search-pasien",
                    search : this.inpNoRujuk,
                    typesearch : this.row.arr_type_jaminan
                }

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    if(!res.data.data){
                        return this.$swal({
                            title: `Data Tidak Ditemukan`,
                            text: 'Data Pasien Tidak Ditemukan',
                            icon: 'error',
                        })
                    }else{
                        let data = res.data.data
                        this.row.arr_ap_id = data.ap_id
                        this.rowPasien = data
                    }
                })
            },
            resetData(){
                this.inpNoRujuk = ""
                this.row.arr_ap_id = null
            },
            selectPoli(e){
                this.row.arr_poli = null
                this.row.arr_nama_dokter = null
                let data = {
                    id: e,
                    type: 'get-poli'
                }
                Gen.apiRest(
                "/do/" + this.modulePage, {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.mPoli = res.data.mPoli
                })
            },
            selectDokter(e) {
                this.row.arr_nama_dokter = null
                let data = {
                    poli: e,
                    id: this.row.arr_faskes,
                    type: 'get-by-poli'
                }
                Gen.apiRest(
                "/do/" + this.modulePage, {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.mDokter = res.data.mDokter
                })
            },
            doSubmitCRUD(IDForm='VForm', refs=false, typeSubmit=false, params={}){
                console.log("test")
                this.row.tipeRujukan = this.dataRM.artlr_type
                this.row.poliInternal = this.dataRM.artlr_poli
                this.row.poliExternal = this.dataRM.artlr_poli_rujuk
                this.row.faskesRujuk = this.dataRM.artlr_faskes_rujuk
                this.row.arm_id = this.dataRM.artlr_arm_id
                this.row.resepDokter = this.resepDokter
        
                this.$refs['VForm'].validate().then(success => {
                    if(success){
                        return this.doSubmit(
                            "/do/"+this.modulePage, 
                            _.assign(
                                {type:'update'}, 
                                {id: (this.pageId||this.pageSlug)}, 
                                _.clone(this.row)
                            ), 
                            (type, resp)=>{
                                if(type=='success'){
                                    this.$router.push({name:this.$route.name, params:params }).catch(()=>{})
                                }else{
                                    if(resp.response.status==422) {
                                        if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                                        var msg = ""
                                        $.each(Object.keys(resp.response.data),(i,v)=>{
                                            msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                                        })
                                        return this.$swal("Blocked With Validation!", msg)
                                        }else{
                                        return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                                        }
                                    }else if(resp.response.status==400){
                                        return this.$swal({
                                        title: resp.response.data.title||resp.response.data.message,
                                        html: resp.response.data.title?resp.response.data.message:"",
                                        icon: 'error',
                                        confirmButtonText: 'Ok'
                                        })
                                    }
                                }
                            },
                            'POST', IDForm, refs
                        )
                    }
                })

            }
        },
        mounted() {
            this.apiGet()
        },
    }
    
</script>